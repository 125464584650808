.table {
  display: table;
}

.table-title {
  display: table-caption;
  text-align: left;
  font-weight: bold;
  font-size: larger;
}

.table-heading {
  display: table-row;
  font-weight: bold;
  text-align: left;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  text-align: left;
  border-bottom: solid;
  border-width: thin;
  padding-left: 5px;
  padding-right: 5px;
}
